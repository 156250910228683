// extracted by mini-css-extract-plugin
export var gallery__imageWrapper = "nb-module--gallery__imageWrapper--ZxWpe";
export var gallery_fullScreen = "nb-module--gallery_fullScreen--f7Mew";
export var gallery__image = "nb-module--gallery__image--AxLsO";
export var gallery__closeNote = "nb-module--gallery__closeNote--fkojr";
export var gallery__closeNote_isVisible = "nb-module--gallery__closeNote_isVisible--amf1B";
export var swiperWrapper = "nb-module--swiperWrapper--FAOgD";
export var productImageGallery__thumbs = "nb-module--productImageGallery__thumbs--LIN9L";
export var productImageGallery__thumbSlide = "nb-module--productImageGallery__thumbSlide--MAacy";
export var productImageGallery__thumbSlide_active = "nb-module--productImageGallery__thumbSlide_active--oXwNq";
export var productImageGallery__thumbsSlider = "nb-module--productImageGallery__thumbsSlider--MoAGB";
export var productImageGallery__thumbsSlider__pagination = "nb-module--productImageGallery__thumbsSlider__pagination--Su4nX";
export var productImageGallery__thumbsSlider__pagination_active = "nb-module--productImageGallery__thumbsSlider__pagination_active--yd9rv";